import BackendService from './BackendService';

export default class RateplanService extends BackendService {

    constructor() {
        super();
    }
    createRatePlan(contract) {

        if(contract.rateplan_link.rateplan!==""){
            contract.link_rateplan = contract.rateplan_link.rateplan;
            contract.link_value = contract.rateplan_link.value,
            contract.link_type = contract.rateplan_link.type
        }

       //console.log('contrato, con: ', contract)
        //return true;
        return this.httpCall('rateplan',{
            method: 'POST',
            data: contract,
        });

    }
    updateRatePlan(contract) {

        if(contract.rateplan_link.rateplan!==""){
            contract.link_rateplan = contract.rateplan_link.rateplan;
            contract.link_value = contract.rateplan_link.value,
                contract.link_type = contract.rateplan_link.type
        }

       //console.log('contrato, con: ', contract)
        return this.httpCall('rateplan/'+contract._id,{
            method: 'PUT',
            data: contract,
        });

    }
    deletetRateConfig(rateConfig_id) {
        return this.httpCall('rate-config/'+rateConfig_id,{
            method: 'delete'
        });
    }


    getRateplanId(contract_id) {

        return this.httpCall('rateplan/'+contract_id);

        //return this.httpCall('property' + property_id + '/users');
    }

    createRateConfig(dataForm) {
       //console.log('createRateConfig, con: ', dataForm)
        return this.httpCall('rate-config',{
            method: 'POST',
            data: dataForm,
        });
    }
    updateRateConfig(dataForm) {
       //console.log('createRateConfig, con: ', dataForm)
        return this.httpCall('rate-config/'+dataForm._id,{
            method: 'PUT',
            data: dataForm,
        });
    }

    deleteRateConfig(rateConfig_id) {
        return this.httpCall('rate-config/'+rateConfig_id,{
            method: 'delete'
        });
    }



    getRateConfig(parameters) {
        return this.httpCall('rates-config',{
            params: parameters,
        });
    }

   /* createRateRack(racks) {
        console.log('createRateRack, con: ', racks)
        /*return this.httpCall('rateplan/'+rateplan_id+'/baserate',{
            method: 'POST',
            data: racks,
        });
    }*/
    createRateRack(rateplan_id, item, idx) {
        //console.log('createRateRack, con: ', rateplan_id, item, idx)
        return this.httpCall('rateplan/'+rateplan_id+'/baserate',{
            method: 'POST',
            data: {
                name: item.name,
                value: item.value,
                order: (parseInt(idx)+1)
            },
        });
    }

    updateRateRack(rateplan_id, item, idx) {
        //console.log('updateRateRack, con: ', rateplan_id, item, idx)
        return this.httpCall('rateplan/'+rateplan_id+'/baserate/'+item._id,{
            method: 'PUT',
            data: {
                name: item.name,
                value: item.value,
                order: (parseInt(idx)+1)
            },
        });
    }
    deleteRateRack(rateplan_id, raterack_id) {
        return this.httpCall('rateplan/'+rateplan_id+'/baserate/'+raterack_id,{
            method: 'delete'
        });
    }



}
