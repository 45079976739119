<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <h4 class="title-in-h">{{info_page.header}}</h4>
        </div>
        <breadcrumb >
            <breadcrumb-item  v-for="breadcrumb in info_page.breadcrumbs" v-if="breadcrumb.type!=='active'">
                <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active v-else>
                {{breadcrumb.name}}
            </breadcrumb-item>
        </breadcrumb>

        <card class="mt-2"  v-loading="screenLoading"
              element-loading-lock="true"
              :element-loading-text="screenText"
              element-loading-customClass="loading-large"
              element-loading-spinner="el-icon-loading">
        <div class="box-form">

            <form>
                <div class="row">
                    <div class="col-md-4">
                        <fg-input type="text"
                                  :name="$t('form.name')"
                                  :label="$t('form.name')"
                                  :placeholder="$t('form.name')"
                                  v-validate="modelValidations.name"
                                  :error="getError($t('form.name'))"
                                  v-model="rateplan.name">
                        </fg-input>
                    </div>
                    <div class="col-md-4">
                       <fg-input type="text"
                                  @input="rateplan.cod = rateplan.cod.toUpperCase()"
                                  :name="$t('contract.form.code')"
                                  :label="$t('contract.form.code')"
                                  :placeholder="$t('contract.form.code')"
                                  v-validate="modelValidations.code"
                                  :error="getError($t('contract.form.code'))"
                                  v-model="rateplan.cod"
                                 :disabled="(info_page.action==='edit')"
                                  >
                        </fg-input>
                    </div>
                    <div class="col-md-4">
                        <div class="form-check is_public">
                            <label for="is_public" class="form-check-label check-admin">
                                <input class="form-check-input" type="checkbox" id="is_public" v-model="rateplan.is_public">
                                <span class="form-check-sign" ></span> Público
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row" v-show="rateplansShow()">

                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.vinculo')}}</label>
                            <el-select class="select-default" size="large" :placeholder="$t('contract.form.vinculo')"
                                       v-model="rateplan.rateplan_link.rateplan" @change="contractType">
                                <el-option v-for="option in generalInfo.rateplans" class="select-default" :value="option._id"
                                           :label="option.name" :key="option._id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-4 custom-error">
                        <div class="form-group has-label pt-custom" v-show="rateplan.rateplan_link.rateplan && rateplan.rateplan_link.rateplan!==''">
                            <el-select class="select-default" size="large" :placeholder="$t('contract.form.link_type')"  :name="$t('contract.form.link_type')"
                                       v-model="rateplan.rateplan_link.type" >
                                <el-option v-for="option in generalInfo.format_payment" class="select-default" :value="option._id"
                                           :label="option.name" :key="option._id">
                                </el-option>
                            </el-select>
                            <label class="text-danger"  v-show="rateplan.errors.linksType">El campo tipo de link es obligatorio</label>
                        </div>
                    </div>
                    <div class="col-md-4  pt-custom  custom-error"  v-show="rateplan.rateplan_link.rateplan && rateplan.rateplan_link.rateplan!==''">

                        <fg-input type="text"
                                  class="mb-0"
                                  :name="$t('contract.form.link_value')"
                                  :placeholder="$t('contract.form.link_value')"
                                  v-model="rateplan.rateplan_link.value"
                                  :disabled="rateplan.rateplan_link.type=='='?true:false"
                        >
                        </fg-input>
                        <label class="text-danger"  v-show="rateplan.errors.linksValue">El campo cantidad es obligatorio</label>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.type')}}</label>

                            <fg-input v-show="rateplan.contract_base"
                                      v-validate="modelValidations.meal_plan"  :name="$t('contract.form.type')"  v-model="rateplan.meal_plan"
                                      :error="getError($t('contract.form.type'))">
                                <el-select class="select-default" size="large" :placeholder="$t('contract.form.type')"  v-validate="modelValidations.meal_plan" :name="$t('contract.form.type')"
                                           v-model="rateplan.meal_plan"  :error="getError($t('contract.form.type'))" @change="checkType()"
                                >
                                    <el-option v-for="option in generalInfo.types" class="select-default" :value="option._id"
                                               :label="option.name" :key="option._id">
                                    </el-option>
                                </el-select>
                            </fg-input>


                            <fg-input  v-show="!rateplan.contract_base"
                                        :value="contractMealplan(rateplan.meal_plan)"
                                        disabled="true">
                            </fg-input>




                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.currency')}}</label>


                            <fg-input v-show="(info_page.action==='add') && rateplan.contract_base"
                                      v-validate="modelValidations.currency"  :name="$t('contract.form.currency')"  v-model="rateplan.currency"
                                      :error="getError($t('contract.form.currency'))">
                                <el-select class="select-default" size="large" :placeholder="$t('contract.form.currency')"  v-validate="modelValidations.currency" :name="$t('contract.form.currency')"
                                           v-model="rateplan.currency"  :error="getError($t('contract.form.currency'))" @change="filterRateplans()">
                                    <el-option v-for="option in generalInfo.currencys" class="select-default"  :value="option._id"
                                               :label="option.name" :key="option._id">
                                    </el-option>
                                </el-select>
                            </fg-input>

                            <fg-input v-show="(info_page.action==='add') && !rateplan.contract_base"
                                      v-validate="modelValidations.currency"  :name="$t('contract.form.currency')"  v-model="rateplan.currency"
                                      :error="getError($t('contract.form.currency'))" disabled="true">
                            </fg-input>


                            <fg-input type="text"
                                      v-model="rateplan.currency"
                                      :disabled="(info_page.action==='edit')"
                                      v-show="info_page.action==='edit'"
                            >
                            </fg-input>
                        </div>
                    </div>

                    <div class="col-md-4">

                    </div>
                </div>

                <div data-info="rooms-simple" class="box-rooms"  v-show="!rateplan.roomLinked">
                    <!--<div class="row" v-show="contract_base || contract.meal_plan<3">-->
                    <div data-info="headers" class="row" >

                        <div class="offset-3 col-md-9 pb-0 pl-5">
                            <h4 class="mb-0 title-b" v-show="!rateplan.contract_base">Resultado tarifas vinculadas</h4>
                        </div>

                        <div class="col-md-4 pb-0">
                            <label >{{$t('contract.form.add_rooms')}}</label>
                        </div>
                        <div class="col-md-7 pb-0">
                            <div class="row"  v-show="rateplan.inclusiveAll">
                                <div class="col-md-2 pl-3 pb-0">

                                    <label >{{$t('contract.form.simple')}}</label>
                                </div>
                                <div class="col-md-2 pl-3 pb-0">
                                    <label >{{$t('contract.form.double')}}</label>
                                </div>
                                <div class="col-md-2 pl-3 pb-0">

                                    <label >{{$t('contract.form.triple')}}</label>
                                </div>
                                <div class="col-md-2 pl-3 pb-0">

                                    <label >{{$t('contract.form.cuadruple')}}</label>
                                </div>
                                <div class="col-md-2 pl-3 pb-0"  v-if="info_page.jrs">
                                    <label >{{$t('contract.form.jr')}}</label>
                                </div>
                                <div class="col-md-2 pl-3 pb-0"  v-if="info_page.children">
                                    <label >{{$t('contract.form.childs')}}</label>
                                </div>
                            </div>
                            <div class="row"  v-show="!rateplan.inclusiveAll">
                                <div class="col-md-2 pb-0">

                                    <label >{{$t('contract.form.rate_base')}}</label>
                                </div>
                                <div class="col-md-2 pb-0">
                                    <label >{{$t('contract.form.extra_pax')}}</label>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div data-info="forms" class="row" v-for="(room, n) in rateplan.infoRooms">
                        <div class="col-md-4 custom-error">


                            <fg-input :value="roomName(room.room)" disabled="true" v-if="generalInfo.hasLinkRateplan">
                            </fg-input>


                            <fg-input v-model="room.room" v-show="rateplan.contract_base || room.room ===''" v-if="!generalInfo.hasLinkRateplan"
                                      :name="$t('contract.form.add_rooms')" disabled="true">
                                <el-select class="select-default" size="large"  :name="$t('contract.form.type')"
                                           v-model="room.room" @change="roomInfo(n)" >
                                    <el-option v-for="(option, idx) in generalInfo.rooms" class="select-default" :value="option._id"
                                               v-show="option.status===true" :label="option.name" :key="idx">
                                    </el-option>
                                </el-select>
                            </fg-input>


                           <fg-input  v-show="!rateplan.contract_base && room.room !==''"
                                       v-validate="modelValidations.type"  :name="$t('contract.form.type')"  :value="roomName(room.room)"
                                       :error="getError($t('contract.form.type'))" disabled="true">
                            </fg-input>

                            <label class="text-danger " v-show="rateplan.errors.rooms[n]">Es obligatorio agregar una habitación para continuar</label>
                        </div>
                        <div class="col-md-7"  v-show="rateplan.inclusiveAll">
                            <div class="row" >
                                <div class="col-md-2 pt-0">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.sencilla.value"
                                              v-show="rateplan.contract_base"
                                    >
                                    </fg-input>

                                    <label v-show="!rateplan.contract_base" class="text-muestra" >${{calculateVinculates(n, 'sencilla')}}</label>
                                </div>
                                <div class="col-md-2 pt-0" >
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.double.value"
                                              v-show="rateplan.contract_base">
                                    </fg-input>
                                    <label v-show="!rateplan.contract_base" class="text-muestra" >${{calculateVinculates(n, 'double')}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.triple.value"
                                              v-show="rateplan.contract_base && room.max_capacity>=3">
                                    </fg-input>
                                    <label v-show="!rateplan.contract_base" class="text-muestra" >${{calculateVinculates(n, 'triple')}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.cuadruple.value"
                                              v-show="rateplan.contract_base && room.max_capacity>=4">
                                    </fg-input>
                                    <label v-show="!rateplan.contract_base" class="text-muestra" >${{calculateVinculates(n, 'cuadruple')}}</label>
                                </div>
                                <div class="col-md-2 pt-0" v-if="info_page.jrs">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.jrs_price"
                                              v-show="rateplan.contract_base && room.max_jr>0">
                                    </fg-input>
                                    <label v-show="!rateplan.contract_base && room.max_jr>0" class="text-muestra" >${{calculateVinculates(n, 'jr')}}</label>
                                </div>
                                <div class="col-md-2 pt-0" v-if="info_page.children" >
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.children_price"
                                              v-show="rateplan.contract_base && room.max_children>0">
                                    </fg-input>
                                    <label v-show="!rateplan.contract_base && room.max_children>0" class="text-muestra" >${{calculateVinculates(n, 'children')}}</label>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-1 pt-0" v-show="rateplan.inclusiveAll">
                            <a class="item-minus" v-tooltip.bottom="'Eliminar habitación'">
                                <i class="fa fa-minus"  @click="deleteRoom(n)" v-if="rateplan.infoRooms.length>1 && n !== 0 && !generalInfo.hasLinkRateplan"></i>
                            </a>
                        </div>
                        <div class="col-md-8" v-show="!rateplan.inclusiveAll ">
                            <div class="row">
                                <div class="col-md-2 pt-0 custom-error" >
                                    <fg-input type="text"
                                              :name="$t('contract.form.rate_base')"
                                              v-model="room.base_rate"
                                              @input="rateplan.errors.rates[n] = false"
                                              v-show="rateplan.contract_base"
                                    >
                                    </fg-input>
                                    <label v-show="!rateplan.contract_base" class="text-muestra" >${{calculateVinculates(n, 'base_rate')}}</label>

                                    <label class="text-danger "  v-show="rateplan.errors.rates[n]">El campo Tarifa base es obligatorio</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <fg-input type="text"
                                              :name="$t('contract.form.link_value')"
                                              v-model="room.extra_pax"
                                              v-show="rateplan.contract_base">
                                    </fg-input>
                                    <label v-show="!rateplan.contract_base" class="text-muestra">${{calculateVinculates(n, 'extra_pax')}}</label>
                                </div>
                                <div class="col-md-2 pt-0">
                                    <a class="item-minus" v-tooltip.bottom="'Eliminar habitación'">
                                        <i class="fa fa-minus"  @click="deleteRoom(n)" v-if="rateplan.infoRooms.length>0  && !generalInfo.hasLinkRateplan"></i>
                                    </a>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div class="col-12 custom-error pl-3"  v-show="!rateplan.roomLinked">
                        <label class="text-danger">{{rateplan.errors.roomsText}}</label>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <a class="btn-plus"  v-show="rateplan.infoRooms.length < generalInfo.rooms.length - 1 ">
                                <i class="fa fa-plus" @click="addRoom()" v-tooltip.bottom="'Agregar habitación'"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="row" v-show="rateplan.contract_base ">
                    <div class="box-controller">
                        <div class="row">
                            <div class="col-md-9 head-section">
                                <div class="box-header">
                                    <div class="form-check">
                                        <label for="is_public2" class="form-check-label check-admin subtitle">
                                            <input class="form-check-input" type="checkbox" id="is_public2" v-model="rateplan.roomLinked">
                                            <span class="form-check-sign" ></span>
                                            Habitaciones vinculadas
                                        </label>
                                    </div>
                                    <label class="lbl-subtitle">
                                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Todas las habitaciones se vinculan a la habitación base. </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contain-links" :class="rateplan.inclusiveAll && rateplan.roomLinked?'has-scroll':''">
                        <div class="box-room-links" :class="rateplan.inclusiveAll?'extendible':'normal'">
                            <div class="head-section">
                                <div class="row">
                                    <div class="col-md-9">

                                    </div>

                                    <div class="col-md-3"  v-show="rateplan.roomLinked">
                                        <h4 class="results-rates" v-show="!rateplan.inclusiveAll">Resultado tarifas vinculadas</h4>
                                    </div>
                                </div>
                                <div class="row ml-0 mr-0" v-show="rateplan.roomLinked">
                                    <div class="col-md-3 pb-0  pl-0 " :class="rateplan.inclusiveAll?'super-top':''">
                                        <div class="bg-white">

                                        </div>

                                    </div>
                                    <div class="pb-0" :class="rateplan.inclusiveAll?'col-md-9 overflow-in-x':'col-md-6'">
                                        <div class="box-links-rates">
                                            <div class="group-item" v-for="head in generalInfo.info_links.title.normal" v-show="!rateplan.inclusiveAll">
                                                <label>{{head}}</label>
                                            </div>
                                            <div class="group-item" v-for="head in generalInfo.info_links.title.extendible" v-show="rateplan.inclusiveAll">
                                                <label>{{head}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="box-resume"  v-show="!rateplan.inclusiveAll">
                                            <label v-for="head in generalInfo.info_links.title.normal" v-show="!rateplan.inclusiveAll">{{head}}</label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="item-link"  v-for="(room_link, idx_) in rateplan.infoRooms" v-show="rateplan.roomLinked">

                                <div class="row ml-0 mr-0">
                                    <div class="col-md-3" :class="rateplan.inclusiveAll?'super-top':''">

                                        <i class="fa fa-star" v-show="(idx_===0)" v-tooltip.bottom="'Habitación base'"></i>
                                        <fg-input type="text"
                                                  class="input-false-default primary"
                                                  :class="(idx_===0)?'class_base':'no-icon'"
                                                  v-if="generalInfo.hasLinkRateplan"
                                                  disabled="true"
                                                  :value="roomName(room_link.room)">
                                        </fg-input>


                                        <el-select class="select-default primary"
                                                   :class="(idx_===0)?'class_base':'no-icon'"
                                                   size="large"
                                                   :placeholder="(idx_===0)?'Seleccionar hab. base':'Seleccionar habitación'"
                                                   v-if="!generalInfo.hasLinkRateplan"
                                                   v-model="room_link.room"  @change="roomInfo(idx_)" >
                                            <el-option v-for="(option, idx) in generalInfo.rooms" class="select-default" :value="option._id"
                                                       v-show="option.status===true" :label="option.name" :key="idx">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div :class="rateplan.inclusiveAll?'col-md-9 overflow-in-x':'col-md-6'">
                                        <div class="box-links-rates">
                                            <div class="group-normal" v-if="!rateplan.inclusiveAll">
                                                <div class="group-item">
                                                    <fg-input type="text"
                                                              class="input-default"
                                                              :class="(idx_===0)?'class_base':''"
                                                              placeholder=""
                                                              v-model="room_link.base_rate">
                                                    </fg-input>
                                                    <el-select class="select-default"  size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                               v-model="room_link.base_link" @change="" v-show="(idx_!==0)">
                                                        <el-option v-for="option in generalInfo.format_payment" :value="option._id"
                                                                   :label="option.name" :key="option._id">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                                <div class="group-item">
                                                    <fg-input type="text"
                                                              class="input-default"
                                                              placeholder=""
                                                              :class="(idx_===0)?'class_base':''"
                                                              v-model="room_link.extra_pax">
                                                    </fg-input>
                                                    <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                               v-model="room_link.extra_pax_link">
                                                        <el-option v-for="option in generalInfo.format_payment" class="select-default" :value="option._id"
                                                                   :label="option.name" :key="option._id">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                            <div class="group-extendible large-scroll" v-if="rateplan.inclusiveAll">
                                                <div class="group-item">
                                                    <fg-input type="text"
                                                              class="input-default"
                                                              placeholder=""
                                                              v-model="room_link.sencilla.value"
                                                    >
                                                    </fg-input>
                                                    <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                               v-model="room_link.sencilla.link">
                                                        <el-option v-for="option in generalInfo.format_payment" :value="option._id"
                                                                   :label="option.name" :key="option._id">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                                <div class="group-item">
                                                    <fg-input type="text"
                                                              class="input-default"
                                                              placeholder=""
                                                              :class="(idx_===0)?'class_base':''"
                                                              v-model="room_link.double.value">
                                                    </fg-input>
                                                    <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                               v-model="room_link.double.link"  v-show="(idx_!==0)">
                                                        <el-option v-for="option in generalInfo.format_payment" class="select-default" :value="option._id"
                                                                   :label="option.name" :key="option._id">
                                                        </el-option>
                                                    </el-select>
                                                </div>

                                                <div class="group-item">
                                                    <fg-input type="text"
                                                              class="input-default"
                                                              placeholder=""
                                                              v-model="room_link.triple.value">
                                                    </fg-input>
                                                    <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                               v-model="room_link.triple.link">
                                                        <el-option v-for="option in generalInfo.format_payment" :value="option._id"
                                                                   :label="option.name" :key="option._id">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                                <div class="group-item">
                                                    <fg-input type="text"
                                                              class="input-default"
                                                              placeholder=""
                                                              v-model="room_link.cuadruple.value">
                                                    </fg-input>
                                                    <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                               v-model="room_link.cuadruple.link">
                                                        <el-option v-for="option in generalInfo.format_payment" class="select-default" :value="option._id"
                                                                   :label="option.name" :key="option._id">
                                                        </el-option>
                                                    </el-select>
                                                </div>

                                                <div class="group-item"  v-if="info_page.jrs">
                                                    <div class="box-inside" v-show="room_link.max_jr>0">
                                                        <fg-input type="text"
                                                                  class="input-default"
                                                                  placeholder=""
                                                                  v-model="room_link.jrs_price">
                                                        </fg-input>
                                                        <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                                   v-model="room_link.jr_link_type">
                                                            <el-option v-for="option in generalInfo.format_payment" class="select-default" :value="option._id"
                                                                       :label="option.name" :key="option._id">
                                                            </el-option>
                                                        </el-select>
                                                    </div>
                                                </div>

                                                <div class="group-item"  v-if="info_page.children">
                                                    <div class="box-inside" v-show="room_link.max_children>0">
                                                        <fg-input type="text"
                                                                  class="input-default"
                                                                  placeholder=""
                                                                  v-model="room_link.children_price">
                                                        </fg-input>
                                                        <el-select class="select-default" size="large" placeholder="GAP"  :name="$t('contract.form.link_type')"
                                                                   v-model="room_link.children_link_type">
                                                            <el-option v-for="option in generalInfo.format_payment" class="select-default" :value="option._id"
                                                                       :label="option.name" :key="option._id">
                                                            </el-option>
                                                        </el-select>
                                                    </div>

                                                </div>
                                                <div class="item-minus">
                                                    <i class="fa fa-minus"  @click="deleteRoom(idx_)" v-if="idx_ !== 0 && !generalInfo.hasLinkRateplan"></i>
                                                </div>
                                            </div>





                                        </div>
                                    </div>
                                    <div class="col-md-3"  v-show="!rateplan.inclusiveAll">
                                        <div class="box-resume w-border">
                                            <label>${{calculateResults(idx_, 'base_rate')}}</label>
                                            <label>${{calculateResults(idx_, 'extra_pax')}}</label>
                                        </div>
                                        <div class="item-minus">
                                            <i class="fa fa-minus"  @click="deleteRoom(idx_)" v-if="!generalInfo.hasLinkRateplan"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>

                        <div class="col-12 custom-error pl-3"  v-if="rateplan.roomLinked && rateplan.errors.roomsText">
                            <label class="text-danger">{{rateplan.errors.roomsText}}</label>
                        </div>


                        <div class="col-md-12 text-right head-top"  v-show="rateplan.roomLinked">

                            <h4 class="head-resumen" v-show="rateplan.inclusiveAll && rateplan.roomLinked">Resultado tarifas vinculadas</h4>

                            <a class="btn-plus room-link"   v-show="rateplan.infoRooms.length < generalInfo.rooms.length - 1 " >
                                <i class="fa fa-plus" @click="addRoom()" v-tooltip.bottom="'Agregar habitación'"></i>
                            </a>
                        </div>
                        <div class="box-resume-extendible" v-show="rateplan.inclusiveAll && rateplan.roomLinked">
                            <div class="item-link">
                                <div class="row">
                                    <div class="col-md-3  super-top">

                                        <label class="big-size head">Habitación</label>
                                    </div>
                                    <div class="col-md-9 padding-15x   overflow-in-x">
                                        <label v-for="head in generalInfo.info_links.title.extendible" v-show="rateplan.inclusiveAll" class="head">{{head}}</label>
                                    </div>
                                </div>

                            </div>
                            <div class="item-link" v-for="(room_link, idx) in rateplan.infoRooms">
                                <div class="row">
                                    <div class="col-md-3 super-top">
                                        <label class="big-size">{{roomName(room_link.room)}}</label>
                                    </div>
                                    <div class="col-md-9  overflow-in-x">

                                        <label>${{calculateResults(idx, 'sencilla')}}</label>
                                        <label>${{calculateResults(idx, 'double')}}</label>
                                        <label>${{calculateResults(idx, 'triple')}}</label>
                                        <label>${{calculateResults(idx, 'cuadruple')}}</label>
                                        <label v-if="info_page.jrs" > <span v-show="room_link.max_jr>0">${{calculateResults(idx, 'jr')}}</span></label>
                                        <label v-if="info_page.children"> <span v-show="room_link.max_children>0">${{calculateResults(idx, 'children')}}</span></label>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>


                </div>





                <div class="row">

                    <div class="col-md-4 custom-error">
                        <label >{{$t('contract.form.type_payment')}}</label>
                        <div class="form-check"  v-for="payment in generalInfo.payments" v-if="payment.status">
                            <label :for="payment._id" class="form-check-label">
                                <input class="form-check-input" type="checkbox" :id="payment._id" v-model="rateplan.payment_options" :value="payment._id"
                                       @input="rateplan.errors.payments = false">
                                <span class="form-check-sign" ></span>{{payment.name}}

                            </label>
                        </div>
                        <span v-if="!generalInfo.payments_status" class="item-empty">No tienes agregado ningún tipo de pago</span>

                        <label class="text-danger "  v-show="rateplan.errors.payments">Es obligatorio agregar un tipo de pago para continuar</label>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.politics')}}</label>
                            <fg-input v-model="rateplan.cancellation_policy" >
                                <el-select class="select-default" size="large"  :name="$t('contract.form.type')"
                                           v-model="rateplan.cancellation_policy">
                                    <el-option v-for="(option, idx) in generalInfo.annulments" class="select-default" :value="option._id"
                                               :label="option.name" :key="idx">
                                    </el-option>
                                </el-select>
                            </fg-input>
                        </div>
                    </div>
                </div>
                <div class="row pl-3" v-show="rateplan.contract_base && rateplan.roomLinked">
                    <div class="col-md-6 mt-3  custom-error">
                        <label class="mb-2 subtitle" >Tarifas</label>
                        <div class="form-rates-ext">
                            <div class="item-group" v-for="(racke, ky) in rateplan.rates_list">
                                <label>{{assigNameRack(ky)}}</label>
                                <fg-input v-model="rateplan.rates_list[ky]" @input="rateplan.errors.racks = false">
                                </fg-input>
                                <span class="fa fa-minus"  @click="deleteRack(ky)" ></span>
                            </div>
                            <div class="item-group">
                                <span class="fa fa-plus-circle plus-item" @click="addRack" ></span>
                            </div>
                        </div>
                        <label class="text-danger "  v-show="rateplan.errors.racks">Se deben llenar todos los campos antes de continuar</label>
                    </div>
                </div>


                <div class="text-center mt-3 mb-3">
                    <button type="submit" class="btn btn-success btn-fill btn-wd mr-2" @click.prevent="sendForm(info_page.action)">
                        {{info_page.button}}
                    </button>
                    <button type="buttton" class="btn btn-danger btn-fill btn-wd" @click.prevent="$router.push({name: 'Contracts'})">
                        {{$t('form.cancel')}}
                    </button>
                </div>
                <div class="clearfix"></div>
            </form>
        </div>
    </card>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import {Select, Option, Tag} from 'element-ui'
    import { Checkbox, Breadcrumb, BreadcrumbItem } from 'src/components/index'
    import RoomService from '../../../js/services/RoomService';
    import ContractService from '../../../js/services/ContractService';
    import AnnulmentService from '../../../js/services/AnnulmentService';
    import PropertyService from '../../../js/services/PropertyService';
    import RateplanService from '../../../js/services/RateplanService';

    import {VTab, VueTabs} from 'vue-nav-tabs'
    import Fuse from 'fuse.js'
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress';


    const roomService = new RoomService();
    const contractService = new ContractService();
    const annulmentService = new AnnulmentService();
    const propertyService = new PropertyService();
    const rateplanService = new RateplanService;
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

    export default {
        components: {
            Checkbox,
            Breadcrumb,
            BreadcrumbItem,
            [Option.name]: Option,
            [Select.name]: Select,
            [Tag.name]: Tag,
            vueTopprogress
        },
        data() {
            return {
                screenLoading: false,
                screenText: '',
                info_page:{
                    action: 'add',
                    header: this.$t('contract.new.title'),
                    button:  this.$t('form.save'),
                    breadcrumbs:[
                        {
                            name: this.$t('property.index.home'),
                            path: '/admin/properties',
                            type: '',
                        },

                        {
                            name: this.$t('contract.index.title'),
                            path: '/admin/contracts',
                            type: '',
                        },

                        {
                            name: this.$t('contract.new.header'),
                            path: '',
                            type: 'active',
                        }

                    ],
                    property: null,
                    jrs: false,
                    children: false
                },
                generalInfo:{
                    types: [],
                    hasLinkRateplan:false,
                    //hasLinkRateplan:true,
                    payment:{
                        pay_hotel: false,
                        pay_paypal: false,
                        pay_tdc: false
                    },
                    info_links:{
                        title:{
                            normal: ['Tarifa Base', 'Extra Pax'],
                            //extendible: ['Sencilla', 'Doble', 'Triple', 'Cuadruple', 'Jr']
                            extendible: ['Sencilla', 'Doble', 'Triple', 'Cuadruple']
                        }
                    },
                    format_payment:[
                        {
                            _id: '+$',
                            name: '( + ) $',
                            value: '1',
                            type: '$'

                        },
                        {
                            _id: '-$',
                            name: '( - ) $',
                            value: '-1',
                            type: '$'

                        },
                        {
                            _id: '+%',
                            name: '( + ) %',
                            value: '1',
                            type: '%'
                        },
                        {
                            _id: '-%',
                            name: '( - ) %',
                            value: '-1',
                            type: '%'
                        },
                        {
                            _id: '=',
                            name: '=',
                            value: '0',
                            type: '='
                        }
                    ],
                    currencys:[
                        {
                            _id: 'MXN',
                            name: 'MXN',

                        },
                        {
                            _id: 'USD',
                            name: 'USD',

                        }
                    ],
                    payments_status: false,
                    payments: [],
                    racks: [],
                    rateplans: [{
                        _id:'',
                        name: 'Seleccionar'
                    }],
                    rooms:[{
                        _id:'',
                        max_capacity: 0,
                        name: 'Seleccionar'
                    }],
                    annulments:[{
                        _id:'',
                        name: 'Seleccionar'
                    }],
                    rateConfigsBase: [],
                    rateplansRateConfigs: [],
                    roomsArrays: [],
                    baseRoom: {
                        room: null
                    },
                    rateplansBD: [],
                    roomsDB:[],
                    rates_list: []
                },
                rateplan:{
                    _id: '',
                    property_id: null,
                    inclusiveAll: false,
                    roomLinked: false,
                    contract_base: true,
                    name: '',
                    cod: '',
                    meal_plan: '',
                    currency: '',
                    is_public: false,
                    cancellation_policy: '',
                    status: true,
                    infoRooms:[],
                    payment_options: [],
                    rates_list : [],
                    rateplan_link: {
                        rateplan: '',
                        type: '',
                        value: '',
                    },
                    errors:{
                        rates: [false],
                        payments: false,
                        racks: false,
                        rooms: false,
                        roomsLinked: false,
                        roomsText: '',
                        roomsLinkedText: '',
                        linksType: false,
                        linksValue: false,
                    }
                },
                modelValidations: {
                    name: {
                        required: true
                    },
                    meal_plan: {
                        required: true
                    },
                    code:{
                        required: true
                    },
                    currency:{
                        required: true
                    },
                    room_id:{
                        required: true
                    },
                    base_rate:{
                        required: true
                    },
                    iva:{
                        required: true
                    },
                },

            }
        },

        mounted() {
            //this.changeProperty()

        },
        created(){
          this.initPage(JSON.parse(localStorage.getItem('property'))._id);
        },
        methods: {


            /*-----------------------------Inicio-----------------------------*/
            async configDatas(property_id){
                let rateplans= [],
                    annulments= [],
                    rooms= [],
                    methodPayments= [],
                    mealPlans= [];

                let status = {
                    methodPayments: false,
                }

                let property = await propertyService.getPropertyId(property_id);
                property = property.data.data.property;
                this.rateplan.property_id = property_id;

                //console.log('property', property)

                /****************Metodos de pagos**************/
                if(property.pay_tdc){
                   methodPayments.push({
                       _id: 'TDD',
                       name: 'Tarjeta de crédito/debito',
                       status: true
                   })
                    status.methodPayments = true;
                }
                if(property.pay_paypal){
                    methodPayments.push({
                        _id: 'PAYPAL',
                        name: 'PayPal',
                        status: true
                    })
                    status.methodPayments = true;
                }
                if(property.pay_hotel){
                    methodPayments.push({
                        _id: 'ARRIVE',
                        name: 'Pago en destino',
                        status: true
                    })
                    status.methodPayments = true;
                }
                /****************Tipos de tarifas**************/
                if(property.plan_ep){
                    mealPlans.push({
                        _id: 1,
                        name: 'Plan Europeo',
                        status: true
                    })
                }
                if(property.plan_epb){
                    mealPlans.push({
                        _id: 2,
                        name: 'Plan Europeo + Desayuno',
                        status: true
                    })
                }
                if(property.plan_ai){
                    mealPlans.push({
                        _id: 3,
                        name: 'Todo Incluido',
                        status: true
                    })
                }
                /****************Contratos bases**************/
                let rateplansResponse = await contractService.getContracts('true', property_id);
                rateplansResponse = rateplansResponse.data.data.rateplans;

                //console.log('rateplansResponse', rateplansResponse)

                rateplans.push({
                    _id: '',
                    name: 'Seleccionar',
                    status: true
                });
                rateplansResponse.forEach(elem=>{
                    if(!elem.rateplan_link){
                        rateplans.push(elem)
                    }
                })



                /****************Politicas de cancelacion**************/

                let annulmentsResponse = await annulmentService.getAnnulments('true', property_id)
                annulmentsResponse = annulmentsResponse.data.data.cancellationPolicys;
                annulments.push({
                    _id: '',
                    name: 'Seleccionar'
                });
                annulments = annulments.concat(annulmentsResponse);


                /****************Habitaciones**************/

                let roomsResponse = await roomService.getRooms('true', property_id)
                roomsResponse = roomsResponse.data.data.rooms;
                rooms.push({
                    _id: '',
                    name: 'Seleccionar'
                });
                rooms = rooms.concat(roomsResponse);


                this.generalInfo.annulments = annulments;
                this.generalInfo.rooms = rooms;
                this.generalInfo.roomsDB = rooms;
                this.generalInfo.rateplans = rateplans;
                this.generalInfo.rateplansBD = rateplans;
                this.generalInfo.payments = methodPayments;
                this.generalInfo.payments_status = status.methodPayments;
                this.generalInfo.types = mealPlans;
                //console.log('generalInfo', this.generalInfo)
            },
            async initPage(property_id){
                this.initLoading();
                await this.configDatas(property_id);
                await this.getInfoProperty(property_id);
                this.checkRoute();
            },
            async contractType(){
                let type_contract = this.rateplan.rateplan_link;
                let contracts = this.generalInfo.rateplans;
               //console.log('type_contract', type_contract)

                if(!type_contract.rateplan || type_contract.rateplan===''){
                    this.rateplan.contract_base = true;
                    this.rateplan.currency = '';
                    this.rateplan.meal_plan = '';
                    this.rateplan.link_type = '';
                    this.rateplan.link_value = '';
                    this.rateplan.infoRooms = [];
                }else{
                    let contract_info = contracts.find(item => item._id === type_contract.rateplan)
                    this.rateplan.currency = contract_info.currency;
                    this.rateplan.meal_plan = contract_info.meal_plan;
                    this.rateplan.contract_base = false;

                    this.checkType();
                    this.chargeRateConfigBase(contract_info)

                }
            },
            checkRoute(){
                let contract_id = this.$route.params.id;

                if(this.$route.name==='editContract'){
                    //let index_ky =this.findIndex(contracts, contract_id);
                    //contracts.splice(index_ky, 1);
                    this.info_page.action = 'edit';
                    this.info_page.header = this.$t('contract.edit.header');
                    this.info_page.button = this.$t('form.update');
                    this.info_page.breadcrumbs[this.info_page.breadcrumbs.length-1].name = this.$t('contract.edit.header');
                    this.updateData(contract_id);
                }else{
                    setTimeout(()=>{
                        this.closeLoading();
                    }, 500)
                }

            },
            async getInfoProperty(property_id){
                let propertyResponse = await propertyService.getPropertyId(property_id);
                propertyResponse = propertyResponse.data.data.property;
               //console.log('propertyResponse', propertyResponse);
                this.info_page.property =propertyResponse;
                this.info_page.children = (!propertyResponse.only_adults && propertyResponse.age_child)?true:false;
                this.info_page.jrs = (!propertyResponse.only_adults && propertyResponse.age_jr)?true:false;
                if(!propertyResponse.only_adults && propertyResponse.age_jr){
                    this.info_page.jrs = true;
                    this.generalInfo.info_links.title.extendible.push('Juniors');
                }

                if(!propertyResponse.only_adults && propertyResponse.age_child){
                    this.info_page.children = true;
                    this.generalInfo.info_links.title.extendible.push('Niños');
                }
                //return propertyResponse.data.data.property;age_jr
            },


            async chargeRateConfigBase(rateplan_info){
                let rateconfigs = [];

                //console.log('info_contact', this.contract.rooms);
                let rc_response = await rateplanService.getRateConfig({rateplan:rateplan_info._id});
                rc_response = rc_response.data.data.rateconfigs;
                this.generalInfo.rateConfigsBase = rc_response;
                let baseCost = 0;
                let rooms_info = this.generalInfo.roomsDB;
                let rateplanInfo = this.rateplan;
                this.rateplan.infoRooms = [];
                //let roomsDB = this.generalInfo.roomsDB;
                let roomArray = [];
                this.generalInfo.rooms = [];
                this.generalInfo.rooms.push({
                    _id: '',
                    name: 'Seleccionar',
                })

                await rc_response.forEach((item, key) =>{

                    let responseRoom = rooms_info.find(roomItem=> roomItem._id === item.room)

                    this.generalInfo.rooms.push(responseRoom)
                    //console.log('item', item)

                    let dataRoom={
                        _id:'',
                        room: item.room,
                        rateplan: item.rateplan,
                        cancellation_policy: item.cancellation_policy,
                        max_capacity: responseRoom.max_pax,
                        max_jr: responseRoom.max_jr,
                        max_children: responseRoom.max_children,
                        sencilla: {
                            value: '',
                            link: ''
                        },

                        double: {
                            value: '',
                            link: ''
                        },
                        triple: {
                            value: '',
                            link: ''
                        },
                        cuadruple: {
                            value: '',
                            link: ''
                        },
                        children_price: null,
                        children_link_type: null,
                        jrs_price: null,
                        jr_link_type: null,
                        base_rate: item.base_rate,
                        base_link: null,
                        extra_pax: null,
                        extra_pax_link: null,
                        room_link: null,
                        room_link_type: null,
                        room_link_type_pax: null,
                        assigments:[]


                    }


                    if(!rateplanInfo.inclusiveAll){
                        dataRoom.extra_pax = item.extra_pax;
                        if(item.room_link_type){

                           //console.log('contrato linkeado', item)
                            dataRoom.room_link = item.room_link;
                            dataRoom.room_link_type = item.room_link_type;
                            dataRoom.room_link_type_pax = item.room_link_type_pax;
                            dataRoom.base_link = item.room_link_type;
                            dataRoom.extra_pax_link = item.room_link_type_pax;
                        }
                        dataRoom.extra_pax_link = item.room_link_type_pax;
                       //console.log('dataRoom', dataRoom)
                    }else{
                        let index_one = item.assignments.findIndex(idx_key => idx_key.cant === 1);
                        let index_third = item.assignments.findIndex(idx_key => idx_key.cant === 3);
                        let index_fourth = item.assignments.findIndex(idx_key => idx_key.cant === 4);

                        //console.log('all inclusive')
                        if(index_one>=0){
                            dataRoom.sencilla.value = item.assignments[index_one].value;
                            if(item.assignments[index_one].link){
                                dataRoom.sencilla.link = item.assignments[index_one].link;
                            }
                        }
                        if(index_third>=0){
                            dataRoom.triple.value = item.assignments[index_third].value;
                            if(item.assignments[index_third].link){
                                dataRoom.triple.link = item.assignments[index_third].link;
                            }
                        }
                        if(index_fourth>=0){
                            dataRoom.cuadruple.value = item.assignments[index_fourth].value;
                            if(item.assignments[index_fourth].link){
                                dataRoom.cuadruple.link = item.assignments[index_fourth].link;
                            }
                        }
                        dataRoom.double.value = item.base_rate;

                        dataRoom.children_price = item.children_price;
                        dataRoom.children_link_type = item.children_link_type;
                        dataRoom.jrs_price = item.jrs_price;
                        dataRoom.jr_link_type = item.jr_link_type;


                        if(item.room_link_type && item.room_link_type!=='0' ){
                            dataRoom.room_link = item.room_link;
                            dataRoom.room_link_type = item.room_link_type;
                            dataRoom.room_link_type_pax = item.room_link_type_pax;
                            dataRoom.double.link = item.room_link_type;
                        }else{
                            dataRoom.double.link = '=';
                        }



                    }
                    //console.log('dataRoom', dataRoom)

                    this.rateplan.infoRooms.push(dataRoom);






                })
                /*this.rateConfigsBase = await rateconfigs*/
                //console.log('rate_calculated AI ', this.rateplan.infoRooms);

            },

            async updateData(rateplan_id){

                let data = await rateplanService.getRateplanId(rateplan_id)
                data = data.data.data.rateplan;
               //console.log('data', data);
                for (let prop in data) {
                    this.rateplan[prop] = data[prop];
                }
                this.generalInfo.rates_list = JSON.parse(JSON.stringify(data.rates_list));
                let rateplan = this.rateplan
                //console.log('contrato', data, rateplan);
                let rateConfigLink = [];
                let rooms_aux = [{
                    _id: '',
                    name: 'Seleccionar',
                    status: true
                }]

                if(data.rateplan_link){
                    rateplan.contract_base = false;
                    rateConfigLink = await rateplanService.getRateConfig({rateplan:data.rateplan_link.rateplan})
                    rateConfigLink = rateConfigLink.data.data.rateconfigs;
                   //console.log('rateConfigLink', rateConfigLink);
                    this.generalInfo.rateConfigsBase = rateConfigLink;
                    rateConfigLink.forEach(rateConfig=>{

                        //console.log('rateConfigLink', rateConfig)
                        let infoRoom = this.generalInfo.rooms.find(item=> item._id === rateConfig.room)
                        if(infoRoom){
                            rooms_aux.push(infoRoom)
                        }
                    })

                    this.generalInfo.rooms = rooms_aux;
                }

                let rateconfigBase = await rateplanService.getRateConfig({rateplan:rateplan_id})
                rateconfigBase = rateconfigBase.data.data.rateconfigs;
               //console.log('rateconfigBase', rateconfigBase);
                rateconfigBase.forEach(rateConfig=>{
                    let auxObject = {
                        _id: rateConfig._id,
                        room: rateConfig.room,
                        rateplan: rateConfig.rateplan,
                        cancellation_policy: null,
                        max_capacity: 0,
                        sencilla: {
                            value: '',
                            link: ''
                        },

                        double: {
                            value: '',
                            link: ''
                        },
                        triple: {
                            value: '',
                            link: ''
                        },
                        cuadruple: {
                            value: '',
                            link: ''
                        },
                        children_price: null,
                        children_link_type: null,
                        jrs_price: null,
                        jr_link_type: null,
                        base_rate: rateConfig.base_rate,
                        base_link: null,
                        extra_pax: null,
                        extra_pax_link: null,
                        room_link: null,
                        room_link_type: null,
                        room_link_type_pax: null,
                        assigments:[],

                    }

                    this.generalInfo.rateplansRateConfigs.push(rateConfig._id)

                    let rooms = this.generalInfo.rooms;
                    //console.log('rooms', rooms)
                    if(rateConfig.assignments.length){
                        rateplan.inclusiveAll = true;
                    }

                    let infoRoom = rooms.find(item=> item._id === rateConfig.room)
                    if(infoRoom!==undefined){
                        auxObject.max_capacity = infoRoom.max_pax;
                        auxObject.max_children = infoRoom.max_children;
                        auxObject.max_jr = infoRoom.max_jr;

                        if(rateplan.contract_base){
                            if(!rateplan.inclusiveAll){
                                auxObject.base_rate = rateConfig.base_rate;
                                auxObject.extra_pax = rateConfig.extra_pax
                                auxObject.extra_pax_link = rateConfig.room_link_type_pax
                                /*if(rateConfig.room_link_type_pax){
                                    auxObject.extra_pax_link = auxObject.room_link_type_pax
                                }*/

                                if(rateConfig.room_link_type){
                                    rateplan.roomLinked = true
                                }

                                if(rateplan.roomLinked){
                                    auxObject.base_link = rateConfig.room_link_type;
                                    auxObject.extra_pax_link = rateConfig.room_link_type_pax
                                }

                            }else{
                                let index_one = -1, index_third = -1, index_fourth = -1;



                                index_one = rateConfig.assignments.findIndex(idx_key => idx_key.cant === 1);
                                index_third = rateConfig.assignments.findIndex(idx_key => idx_key.cant === 3);
                                index_fourth = rateConfig.assignments.findIndex(idx_key => idx_key.cant === 4);
                                //console.log('infoRoom', infoRoom)

                                auxObject.double.value = rateConfig.base_rate;

                                if(rateConfig.room_link_type && rateConfig.room_link_type !=='0'){
                                    rateplan.roomLinked = true
                                    auxObject.double.link = rateConfig.room_link_type;
                                }else{
                                    auxObject.double.link = '=';
                                }

                                auxObject.sencilla.value = rateConfig.assignments[index_one].value
                                if(rateConfig.assignments[index_one].link){
                                    auxObject.sencilla.link = rateConfig.assignments[index_one].link;
                                }

                                if(index_third>=0){
                                    auxObject.triple.value = rateConfig.assignments[index_third].value;
                                    if(rateConfig.assignments[index_third].link){
                                        auxObject.triple.link = rateConfig.assignments[index_third].link;
                                    }
                                }
                                if(index_fourth>=0){
                                    auxObject.cuadruple.value = rateConfig.assignments[index_fourth].value;
                                    if(rateConfig.assignments[index_fourth].link){
                                        auxObject.cuadruple.link = rateConfig.assignments[index_fourth].link;
                                    }
                                }
                                auxObject.jrs_price = rateConfig.jrs_price;
                                auxObject.jr_link_type = rateConfig.jr_link_type;
                                auxObject.children_price = rateConfig.children_price;
                                auxObject.children_link_type = rateConfig.children_link_type;







                                //auxObject.double.link = rateConfig.room_link_type;


                            }
                        }
                        else{
                            let rateConfigSelect = rateConfigLink.find(item=> item.room === rateConfig.room)

                           //console.log('rateConfig', rateConfigSelect);
                            if(rateConfigSelect.assignments.length){
                                rateplan.inclusiveAll = true;
                            }

                            if(!rateplan.inclusiveAll){
                                auxObject.base_rate = rateConfigSelect.base_rate;
                                auxObject.extra_pax = rateConfigSelect.extra_pax
                                if(rateConfigSelect.room_link_type_pax){

                                    auxObject.extra_pax_link = rateConfigSelect.room_link_type_pax
                                }

                                if(rateConfigSelect.room_link_type){
                                    auxObject.base_link = rateConfigSelect.room_link_type;
                                    auxObject.room_link = rateConfigSelect.room_link_type;
                                    auxObject.extra_pax_link = rateConfigSelect.room_link_type_pax
                                }

                            }else{
                                let index_one = -1, index_third = -1, index_fourth = -1;

                                index_one = rateConfigSelect.assignments.findIndex(idx_key => idx_key.cant === 1);
                                index_third = rateConfigSelect.assignments.findIndex(idx_key => idx_key.cant === 3);
                                index_fourth = rateConfigSelect.assignments.findIndex(idx_key => idx_key.cant === 4);

                                if(rateConfigSelect.room_link_type){
                                    auxObject.room_link_type = rateConfigSelect.room_link_type;
                                    auxObject.room_link = rateConfigSelect.room_link_type;
                                }
                                auxObject.double.value = rateConfigSelect.base_rate;

                                if(rateConfigSelect.room_link_type){
                                    auxObject.double.link = rateConfigSelect.room_link_type;
                                    if(rateConfigSelect.room_link_type==='0'){
                                        auxObject.double.link = '=';
                                    }
                                }

                                auxObject.sencilla.value = rateConfigSelect.assignments[index_one].value
                                if(rateConfigSelect.assignments[index_one].link){
                                    auxObject.sencilla.link = rateConfigSelect.assignments[index_one].link;
                                }

                                if(index_third>=0){
                                    auxObject.triple.value = rateConfigSelect.assignments[index_third].value;
                                    if(rateConfigSelect.assignments[index_third].link){
                                        auxObject.triple.link = rateConfigSelect.assignments[index_third].link;
                                    }
                                }
                                if(index_fourth>=0){
                                    auxObject.cuadruple.value = rateConfigSelect.assignments[index_fourth].value;
                                    if(rateConfigSelect.assignments[index_fourth].link){
                                        auxObject.cuadruple.link = rateConfigSelect.assignments[index_fourth].link;
                                    }
                                }
                               //console.log('auxObject', auxObject, rateConfig);
                                auxObject.jrs_price = rateConfigSelect.jrs_price;
                                auxObject.jr_link_type = rateConfigSelect.jr_link_type;
                                auxObject.children_price = rateConfigSelect.children_price;
                                auxObject.children_link_type = rateConfigSelect.children_link_type;
                            }
                        }
                        rateplan.infoRooms.push(auxObject)
                    }

                })
                this.filterRateplans()

                this.closeLoading();

            },

            ContractInfo(){
                let linkRateplan = this.rateplan.rateplan_link;

                let link_rateplan = linkRateplan.rateplan;
                let link_value = Number(linkRateplan.value);
                let link_type = linkRateplan.type;




                /*console.log('contract_id:', link_rateplan)
                console.log('link_value:', link_value)
                console.log('link_type:', link_type)*/

                let rooms = this.rateplan.infoRooms;
                //let rate_config = this.rateConfigs;
                let rate_config = this.rateConfigsBase;;

                //console.log('item.room', rooms, rate_config)
                let room_items = [];
                /*if(link_value !== '' && link_type !==''){
                    rooms.forEach((item, idx)=>{

                        let config = rate_config.find(itm => itm.room===item.room_id)
                        //console.log('config', config)

                        switch (link_type) {
                            case '+$':

                                item.base_rate = this.formatMoney(config.base_rate + link_value);
                                item.extra_pax = this.formatMoney(config.extra_pax + link_value);


                                break;
                            case '-$':

                                item.base_rate = this.formatMoney(config.base_rate - link_value);
                                item.extra_pax = this.formatMoney(config.extra_pax - link_value);
                                break;
                            case '-%':
                                item.base_rate = this.formatMoney(config.base_rate - (config.base_rate/100 *link_value));
                                item.extra_pax = this.formatMoney(config.extra_pax - (config.extra_pax/100 *link_value));
                                break;
                            case '+%':
                                item.base_rate = this.formatMoney(config.base_rate + (config.base_rate/100 *link_value));
                                item.extra_pax = this.formatMoney(config.extra_pax + (config.extra_pax/100 *link_value));
                                //item.extra_pax = config.extra_pax;
                                break;
                            default:
                                item.base_rate = this.formatMoney(config.base_rate);
                                item.extra_pax = this.formatMoney(config.extra_pax);
                                break;
                        }
                    });
                }*/
            },


            /*----------------Configuraciones Tipos de contratos----------------*/
            rateplansShow(){
              let statusRow = true;
              /*this.generalInfo.rateplans.forEach(item=>{
                  if(item.status && item._id !== '')
                      statusRow = true;
              })
                return statusRow;*/
              if(this.rateplan.contract_base && this.info_page.action==='edit'){
                  statusRow = false;
              }



                return statusRow;
            },
            checkType(){
                let type_is = this.rateplan.meal_plan;
                this.rateplan.inclusiveAll = (type_is===3);
                this.filterRateplans()

            },
            filterRateplans(){
                let rateplan = this.rateplan;
                let currency = rateplan.currency;
                let mealPlan = rateplan.meal_plan;
                let rateplansAll = this.generalInfo.rateplansBD;
                let arrayAux = [];
                let rateplansAux = [{
                    id: '',
                    name: 'Seleccionar'
                }];


                if(currency!=='' && mealPlan!==''){
                    arrayAux = rateplansAll.filter(item => item.meal_plan === mealPlan && item.currency === currency)
                }else if(mealPlan!=='' && currency === ''){
                    arrayAux = rateplansAll.filter(item => item.meal_plan === mealPlan )
                }else if(mealPlan === '' && currency !== ''){
                    arrayAux =rateplansAll.filter(item.currency === currency)
                }else if(currency==='' && mealPlan===''){
                    arrayAux = rateplansAll;
                }
                arrayAux = arrayAux.filter(item => item._id !== rateplan._id )

                rateplansAux = rateplansAux.concat(arrayAux)
                this.generalInfo.rateplans = rateplansAux;
            },
            contractMealplan(meal_plan){
                let contractTypes = this.generalInfo.types;
                let valueT = '';
                //console.log('meal_plan', meal_plan)
                if(meal_plan && meal_plan!=='' && contractTypes.length){
                    let contract_info = contractTypes.find(item => item._id === meal_plan)
                    valueT = contract_info.name;
                }
                return valueT;
            },

            /*------------------------------Validaciones------------------------*/
            getError (fieldName) {
                return this.errors.first(fieldName);
            },

            validate(action) {
               //console.log('action?', action)
                this.customValidity();
                this.$validator.validateAll().then(isValid => {
                    if(isValid){
                        if( this.customValidity()){
                            if(action==='add'){
                                this.saveItem();
                            }else{
                                this.updateItem();
                            }
                        }

                    }
                })
            },
            customValidity(){
                let error_response = true;


                let rooms = this.rateplan.infoRooms;


                let payments = this.rateplan.payment_options;
                let errores = this.rateplan.errors;

                errores.roomsText = '';
                errores.racks = false;

                errores.linksType = false;
                errores.linksValue = false;


                if(rooms.length===0){
                    errores.rooms = false;
                    errores.roomsText = this.$t('form.error.room.min')
                    error_response = false;
                }



                let rateplan = this.rateplan;
                let racks = this.rateplan.rates_list;
                //console.log('rateplan', rateplan);
                if(rateplan.rateplan_link.rateplan && rateplan.rateplan_link.rateplan !== ''){

                    if(this.validadorCustom([rateplan.rateplan_link.type], 'string')){
                        error_response = false;
                        errores.linksType = true;
                    }
                    if(this.validadorCustom([rateplan.rateplan_link.value], 'number')){
                        error_response = false;
                        errores.linksValue = true;
                    }
                }
                rooms.forEach((room, idx)=>{
                   //console.log('room', room)

                    if(rateplan.inclusiveAll){

                        if(this.validadorCustom([room.room], 'string')){
                            error_response = false;
                            errores.roomsText = this.$t('form.error.room.required')
                        }else{

                            let arrayValidate = [room.sencilla.value, room.double.value];
                            let arrayValidateLinks = [room.sencilla.link, room.double.link];
                            if(room.max_capacity>=3){
                                arrayValidate.push(room.triple.value)
                                arrayValidateLinks.push(room.triple.link)
                            }
                            if(room.max_capacity>=4){
                                arrayValidate.push(room.cuadruple.value)
                                arrayValidateLinks.push(room.cuadruple.link)
                            }
                            if(room.max_jr>0){
                                arrayValidate.push(room.jrs_price)
                                arrayValidateLinks.push(room.jr_link_type)
                            }
                            if(room.max_children>0){
                                arrayValidate.push(room.children_price)
                                arrayValidateLinks.push(room.children_link_type)
                            }
                            if(this.validadorCustom(arrayValidate, 'number')){
                                error_response = false;
                                errores.roomsText = this.$t('form.error.room.number')
                            }
                            if(this.validadorCustom(arrayValidate, 'string')){
                                error_response = false;
                                errores.roomsText = this.$t('form.error.room.required')
                            }
                            if(rateplan.roomLinked){
                                if(this.validadorCustom(arrayValidateLinks, 'string')){
                                    error_response = false;
                                    errores.roomsText = this.$t('form.error.room.required')
                                }

                            }
                        }
                    }else{

                        let arrayValidate = [room.base_rate, room.extra_pax];
                        if(this.validadorCustom(arrayValidate, 'number')){
                            error_response = false;
                            errores.roomsText = this.$t('form.error.room.number')
                        }
                        arrayValidate.push(room.room);
                        if(this.validadorCustom(arrayValidate, 'string')){
                            error_response = false;
                            errores.roomsText = this.$t('form.error.room.required')
                        }

                        if(rateplan.roomLinked){
                            arrayValidate = [room.base_link, room.extra_pax_link];
                            if(this.validadorCustom(arrayValidate, 'string')){
                                error_response = false;
                                errores.roomsText = this.$t('form.error.room.required')
                            }
                        }
                    }


                });




                racks.forEach((rack, idx)=>{
                    if(rack.value===''){
                        error_response = false;
                        errores.racks= true;
                    }
                })





                if(payments.length===0){
                    error_response = false;
                    errores.payments = true;

                }
              // //console.log('verify validity', error_response, errores)

                //return false;
                return error_response;
            },
            validadorCustom(fields, type){
                let booleanStatus = false;
                fields.forEach(field=>{
                    if(type === 'string'){
                        field = field+'';
                        if(field==='null' ||  field==='' || field.trim().length === 0 ){
                            booleanStatus = true ;
                        }
                    }
                    if(type==='number'){
                        if(isNaN(parseFloat(field)) || !isFinite(field)){
                            booleanStatus = true ;
                        }
                    }
                });

                return booleanStatus;

            },

            /*-----------------------------Guardado---------------------------*/
            sendForm(action){
                this.validate(action);
            },
            async saveItem(){
                /*let link_info = this.contract_info.format_payment.find(links => links._id  === this.contract.link_type);

                if(link_info){
                    this.contract.link_type = link_info._id;
                    //this.contract.link_value = this.contract.link_value;
                }*/
                this.initLoading('Guardando')
                let rateplan = this.rateplan;
                //console.log('rateplan', rateplan)
                let racks = rateplan.rates_list;

               //console.log('racks', racks)

                rateplan.status = (rateplan.cancellation_policy!=='');
                this.generalInfo.roomsArrays = [];

                let infoRooms = rateplan.infoRooms;
                infoRooms.forEach(roomItem=>{
                    this.generalInfo.roomsArrays.push(roomItem.room)
                })


                //let responseRateplan = await rateplanService.createRatePlan(rateplan)
                //responseRateplan = responseRateplan.data.data.rateplan;*/
                rateplanService.createRatePlan(rateplan)
                    .then(async (response) => {
                       //console.log('response', response)
                        let responseRateplan = response.data.data.rateplan;
                        rateplan._id = responseRateplan._id;
                        await this.updateRateConfigs(rateplan);
                       //console.log('De igual forma guarda aun con error')

                        this.closeLoading();
                        this.showSwal(this.$t('contract.new.msg'))
                    })
                    .catch(error => {
                       //console.log('error',error, error.err)
                        if(error.response && error.response.data.err === "RatePlan validation failed: cod: cod debe ser único"){
                            this.alertError();
                        }
                        this.closeLoading();
                    })
            },

            async updatesRacks(contract_id, save = false){

                /*let rates = this.rateplan.rates_list;
                let rates_old = this.generalInfo.rates_list;*/
                let ratesRacks = this.rateplan.rates_list;
                if(save){

                    rteplanService.createRateRack(contract_id, ratesRacks);
                }else{

                    rateplanService.updateRateRack(contract_id, ratesRacks);
                }

                /*await rates.forEach((item, idx) => {
                    let index = rates_old.findIndex(elem => elem._id === item._id);
                    if(item._id!==''){
                    }else{
                        //rateplanService.createRateRack(contract_id, item, idx);
                    }
                    if(index>=0){
                        rates_old.splice(index, 1)
                    };
                });

                if(rates_old.length){
                    rates_old.forEach((item, idx) => {
                        rateplanService.deleteRateRack(contract_id, item._id);
                    });
                }*/

            },



            async updateItem(){
                this.initLoading('Guardando')
                /*let link_info = this.contract_info.format_payment.find(links => links._id  === this.contract.link_type);

                if(link_info){
                    this.contract.link_type = link_info._id;
                    //this.contract.link_value = this.contract.link_value;
                }*/
                let rateplan = this.rateplan;
                //console.log('rateplan', rateplan)
                let racks = rateplan.rates_list;

               //console.log('racks', racks)

                rateplan.status = (rateplan.cancellation_policy!=='');


                let infoRooms = rateplan.infoRooms;
                infoRooms.forEach(roomItem=>{
                    this.generalInfo.roomsArrays.push(roomItem.room)
                })



                rateplanService.updateRatePlan(rateplan)
                    .then(async (response) => {
                        let responseRateplan = response.data.data.rateplan;
                        rateplan._id = responseRateplan._id;
                        /*if(rateplan.contract_base){
                            delete rateplan.infoRooms[0].room_link;
                        }
                        console.log('END: rateplansRateConfigs', rateplan.infoRooms)*/
                        await this.updateRateConfigs(rateplan);
                        this.showSwal(this.$t('contract.new.msg'))
                        this.closeLoading();
                    })
                    .catch(error => {
                        if(error.response && error.response.data.err ===  "Validation failed: cod: cod debe ser único"){
                            this.alertError();
                        }
                        this.closeLoading();
                    })
            },




            async updateRateConfigs(rateplan){
                let baseRoom = this.generalInfo.baseRoom;
                let roomsArray = this.generalInfo.roomsArrays;
                let infoRooms = rateplan.infoRooms;



                let roomItem = infoRooms.find(item => item.room === roomsArray[0])
                let rateConfigsArray = this.generalInfo.rateConfigsBase;
                let auxObject = {
                    _id: roomItem._id,
                    room: roomItem.room,
                    rateplan: rateplan._id,
                    property: rateplan.property_id,
                    base_rate: 1
                };
                let assingment = [];


                if(rateplan.contract_base){
                    if(!rateplan.inclusiveAll){
                        auxObject.base_rate = roomItem.base_rate;
                        auxObject.extra_pax = roomItem.extra_pax;
                        if(rateplan.roomLinked){
                            auxObject.room_link_type =  roomItem.base_link;
                            auxObject.room_link_type_pax =  roomItem.extra_pax_link;

                            if(baseRoom.room===null){
                                baseRoom.room = roomItem.room;
                                delete auxObject.room_link_type;
                            }else{
                                auxObject.room_link =  baseRoom.room;
                            }
                        }
                    }

                    if(rateplan.inclusiveAll){
                        let itemObject = {};



                        if(roomItem.sencilla.value>0){
                            itemObject = {
                                cant: 1,
                                value: Number(roomItem.sencilla.value),
                                link: roomItem.sencilla.link
                            }
                            if(roomItem.sencilla.link==='' || !rateplan.roomLinked){
                                delete itemObject.link;
                            }

                            assingment.push(itemObject)
                        }
                        if(roomItem.triple.value>0){

                            itemObject = {
                                cant: 3,
                                value:Number( roomItem.triple.value),
                                link: roomItem.triple.link
                            }
                            if(roomItem.triple.link==='' || !rateplan.roomLinked){
                                delete itemObject.link;
                            }

                            assingment.push(itemObject)
                        }
                        if(roomItem.cuadruple.value>0){

                            itemObject = {
                                cant: 4,
                                value: Number(roomItem.cuadruple.value),
                                link: roomItem.cuadruple.link
                            }
                            if(roomItem.triple.link==='' || !rateplan.roomLinked){
                                delete itemObject.link;
                            }

                            assingment.push(itemObject)

                        }
                        auxObject.base_rate = roomItem.double.value;
                        if(roomItem.double.link && rateplan.roomLinked){
                            auxObject.room_link_type = roomItem.double.link;

                            if(baseRoom.room===null){
                                baseRoom.room = roomItem.room;
                                auxObject.room_link_type = 0;
                            }else{
                                auxObject.room_link =  baseRoom.room;
                            }
                           //console.log('baseRoom', baseRoom, auxObject)
                        }


                        auxObject.assignments= assingment
                       //console.log('auxObject', auxObject)

                        auxObject.jrs_price = roomItem.jrs_price;
                        auxObject.jr_link_type = roomItem.jr_link_type;
                        auxObject.children_price = roomItem.children_price;
                        auxObject.children_link_type = roomItem.children_link_type;
                        if(!rateplan.roomLinked){
                            delete auxObject.room_link;
                            delete auxObject.room_link_type;
                            auxObject.children_link_type = '=';
                            auxObject.jr_link_type = '=';
                        }
                    }
                }

                if(rateplan.status){
                    auxObject.cancellation_policy = rateplan.cancellation_policy
                }
                if(auxObject._id===''){
                    await rateplanService.createRateConfig(auxObject);
                }else{
                    await rateplanService.updateRateConfig(auxObject);
                    let ky_rca = this.generalInfo.rateplansRateConfigs.indexOf(auxObject._id);
                    if(ky_rca>=0){
                        this.generalInfo.rateplansRateConfigs.splice(ky_rca, 1);
                    }
                }
                //infoRooms.splice(0, 1);
                roomsArray.splice(0, 1);

                if(roomsArray.length){
                    await this.updateRateConfigs(rateplan)
                }else{
                    if(this.generalInfo.rateplansRateConfigs.length){
                        this.generalInfo.rateplansRateConfigs.forEach(async itemDelete=>{
                            await rateplanService.deletetRateConfig(itemDelete);
                        })
                    }
                }









                //infoRooms.forEach(async (roomItem, keyRoom)=>{
                    /*if(rateConfigsArray.length){
                        rateConfigsArray.forEach(async rateItem=>{
                            await rateplanService.deleteRateConfig(rateItem);
                        })
                    }*/
                //})
            },

            /*------------------------------General------------------------*/
            showSwal (msg) {

                swal({
                    title: this.$t('contract.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {
                    this.$router.push({
                        name: 'Contracts'
                    });
                });
            },
            alertError(){
                swal({
                    title: 'Código de contrato ya existe',
                    type: 'warning',
                    confirmButtonClass: 'btn btn-danger btn-fill',
                    confirmButtonText: 'Modificar',
                    buttonsStyling: false
                }).then((result)=> {

                });

            },
            formatMoney(value){
                value = Number(value)

                return value.toFixed(2)
            },

            /*------------------------------Acciones habitaciones---------*/

            addRoom(){


                /*if(this.rateplan.infoRooms.length===0){
                    this.rateplan.infoRooms = [{"_id":"","room":"5e1fbabdc84cb90017bf2cdc","rateplan":null,"cancellation_policy":null,"max_capacity":4,"sencilla":{"value":"1200","link":""},"double":{"value":"2000","link":""},"triple":{"value":"3000","link":""},"cuadruple":{"value":"4000","link":""},"jr":{"value":"","link":""},"base_rate":"1000","base_link":"=","extra_pax":"40","extra_pax_link":"-%","room_link":null,"room_link_type":null,"room_link_type_pax":null,"assigments":[]},{"_id":"","room":"5e1fba88c84cb90017bf2cd1","rateplan":null,"cancellation_policy":null,"max_capacity":4,"sencilla":{"value":"2000","link":""},"double":{"value":"4000","link":""},"triple":{"value":"50000","link":""},"cuadruple":{"value":"7000","link":""},"jr":{"value":"","link":""},"base_rate":"2000","base_link":"+$","extra_pax":"40","extra_pax_link":"-%","room_link":null,"room_link_type":null,"room_link_type_pax":null,"assigments":[]}];
                }*/


                this.rateplan.infoRooms.push({
                    _id:'',
                    room: '',
                    rateplan: null,
                    cancellation_policy: null,
                    max_capacity: 0,
                    max_jr: 0,
                    max_children: 0,
                    sencilla: {
                        value: '',
                        link: ''
                    },

                    double: {
                        value: '',
                        link: ''
                    },
                    triple: {
                        value: '',
                        link: ''
                    },
                    cuadruple: {
                        value: '',
                        link: ''
                    },
                    jr:{
                        value: '',
                        link: ''
                    },
                    base_rate: null,
                    base_link: null,
                    extra_pax: null,
                    extra_pax_link: null,
                    children_price: null,
                    children_link_type: null,
                    jrs_price: null,
                    jr_link_type: null,
                    room_link: null,
                    room_link_type: null,
                    room_link_type_pax: null,
                    assigments:[],


                })


               /* this.contract.errors.rooms.push(false);
                this.contract.errors.rates.push(false);*/
                this.checkDifference();
            },
            deleteRoom(n){
                this.rateplan.infoRooms.splice(n, 1)
                /*this.contract.errors.rooms.splice(n, 1)
                this.contract.errors.rates.splice(n, 1)*/
                if(this.rateplan.infoRooms.length){
                    this.rateplan.infoRooms[0].base_link = '=';
                    this.rateplan.infoRooms[0].double.link = '=';
                }
                this.checkDifference();
            },
            roomInfo(n){
                let infoRoom = this.rateplan.infoRooms[n];
                let rooms = this.generalInfo.rooms;
                let rateplanInfo = this.rateplan;

               //console.log('roomInfo', rateplanInfo)
                if(infoRoom.room!==''){
                    let response = rooms.find(item => item._id===infoRoom.room)

                    //console.log('response', response)
                    infoRoom.max_capacity = response.max_pax;
                    infoRoom.max_jr = response.max_jr;
                    infoRoom.max_children = response.max_children;
                }
                if(!rateplanInfo.contract_base){

                   //console.log('contrato ligado', this.generalInfo.rateConfigsBase, infoRoom)
                    let rateConfigsBase = this.generalInfo.rateConfigsBase;
                    let item = rateConfigsBase.find(elem => elem.room === infoRoom.room)

                    if(!rateplanInfo.inclusiveAll){
                        infoRoom.extra_pax = item.extra_pax;
                        infoRoom.base_rate = item.base_rate;

                        if(item.room_link_type){
                            //console.log('contrato linkeado')
                            infoRoom.room_link = item.room_link;
                            infoRoom.room_link_type = item.room_link_type;
                            infoRoom.room_link_type_pax = item.room_link_type_pax;
                            infoRoom.base_link = item.room_link_type;
                            infoRoom.extra_pax_link = item.room_link_type_pax;
                        }
                        //console.log('estandar')
                    }else{
                        let index_one = item.assignments.findIndex(idx_key => idx_key.cant === 1);
                        let index_third = item.assignments.findIndex(idx_key => idx_key.cant === 3);
                        let index_fourth = item.assignments.findIndex(idx_key => idx_key.cant === 4);

                        //console.log('all inclusive')
                        if(index_one>=0){
                            infoRoom.sencilla.value = item.assignments[index_one].value;
                            if(item.assignments[index_one].link){
                                infoRoom.sencilla.link = item.assignments[index_one].link;
                            }
                        }
                        if(index_third>=0){
                            infoRoom.triple.value = item.assignments[index_third].value;
                            if(item.assignments[index_third].link){
                                infoRoom.triple.link = item.assignments[index_third].link;
                            }
                        }
                        if(index_fourth>=0){
                            infoRoom.cuadruple.value = item.assignments[index_fourth].value;
                            if(item.assignments[index_fourth].link){
                                infoRoom.cuadruple.link = item.assignments[index_fourth].link;
                            }
                        }
                        infoRoom.double.value = item.base_rate;
                        if(item.room_link_type){
                            infoRoom.room_link = item.room_link;
                            infoRoom.room_link_type = item.room_link_type;
                            infoRoom.room_link_type_pax = item.room_link_type_pax;
                            infoRoom.double.link = item.room_link_type;
                        }

                        infoRoom.children_price = item.children_price;
                        infoRoom.children_link_type = item.children_link_type;
                        infoRoom.jrs_price = item.jrs_price;
                        infoRoom.jr_link_type = item.jr_link_type;


                    }




                }

                this.checkDifference(n, 'change');


            },
            roomName(room_id){
                //console.log('roomName', room_id)
                if(room_id){
                    let rooms = this.generalInfo.rooms;

                    let room_info = rooms.find(itm => itm._id===room_id)

                    return room_info.name;
                }


            },

            checkDifference(n=null, type=null){

                let  selected_rooms = this.rateplan.infoRooms;
                let full_rooms = this.generalInfo.rooms;

                full_rooms.forEach(item => {

                    item.status = true;
                });
                let contract_id = this.rateplan.link_rateplan

                selected_rooms.forEach(item => {

                    if(type==='change' && contract_id!==''){

                        //this.ContractInfo();
                    }
                    //console.log('encontrados',full_rooms.findIndex(room => room._id === item.room_id))
                    let idx = full_rooms.findIndex(room => room._id === item.room);
                    if(idx>=0){
                        full_rooms[idx].status = false;
                    }
                })
            },

            /******************************************** RACKS **************************************************************/
            addRack(){
                this.rateplan.rates_list.push('');
                /*if(rates_list.length<10)
                {
                }*/
            },
            assigNameRack(idxk){
                let acomp = '';
                if(idxk===0){

                    acomp = 'Rack';
                }else if(idxk===1){
                    acomp = 'Bar';
                }else{
                    acomp = 'Bar '+((idxk));
                }
                return acomp;
            },
            deleteRack(key){

                let array_aux = this.rateplan.rates_list;
                if(array_aux.length>0){
                    array_aux.splice(key, 1);
                }

                /*array_aux.forEach((item, idx)=>{
                    let acomp = '';
                    if(idx===0){
                        acomp = 'Rack';
                    }else if(idx>0){
                        acomp = 'Bar';
                        acomp+=(idx>1)?idx:'';
                    }

                    item.name = acomp;
                })*/
            },

            /*************************Calculos**************************/

            calculateVinculates(idx, type){

                let linkRateplan = this.rateplan.rateplan_link;

                let link_rateplan = linkRateplan.rateplan;
                let link_value = Number(linkRateplan.value);
                let link_type = linkRateplan.type;
                let value_return = 0;
                let pre_value = 0;

               /* console.log('contract_id:', link_rateplan)
                console.log('link_value:', link_value)
                console.log('link_type:', link_type)
                console.log('type:', type)*/

                let infoRooms = this.rateplan.infoRooms;
                let baseCost = 0;
                let roomItem = infoRooms[idx];



                if(infoRooms.length){
                    if(this.rateplan.inclusiveAll){
                        baseCost = infoRooms[0].double.value;

                    }else{
                        baseCost = infoRooms[0].base_rate;
                    }
                }


                //console.log('roomItem:', infoRooms)


                switch (type) {

                    case 'base_rate':
                        value_return = roomItem.base_rate;
                        if(roomItem.room_link && roomItem.room_link!==null){
                            value_return = this.gapFunction(baseCost, roomItem.base_rate, roomItem.base_link)
                        }
                        if(link_type!=='' && link_value!==''){
                            value_return = this.gapFunction(value_return,  link_value, link_type)
                        }
                        break;
                    case 'extra_pax':
                        pre_value = roomItem.base_rate;
                        value_return = roomItem.extra_pax;
                        if(roomItem.extra_pax_link!==null){
                            value_return =this.gapOnly(pre_value, roomItem.extra_pax, roomItem.extra_pax_link)
                        }
                        if(roomItem.room_link && roomItem.room_link!==null){
                            pre_value = this.gapFunction(baseCost, roomItem.base_rate, roomItem.base_link)
                            value_return =this.gapOnly(pre_value, roomItem.extra_pax, roomItem.extra_pax_link)
                        }

                        if(link_type!=='' && link_value!=='' && roomItem.extra_pax_link!==null){
                            pre_value = this.gapFunction(pre_value,  link_value, link_type)
                            value_return =this.gapOnly(pre_value, roomItem.extra_pax, roomItem.extra_pax_link)
                        }/*else if(link_type!=='' && link_value!==''){
                            value_return = this.gapFunction(value_return, link_value, link_type)
                        }*/

                        break;

                    case 'sencilla':
                        value_return =  roomItem.sencilla.value

                        //console.log('roomItem.sencilla.link', idx, baseCost, link_type,link_value)

                        if(roomItem.sencilla.link!==null && roomItem.sencilla.link!==""){
                            if(baseCost>0){
                                /*if(link_type!=='' && link_value!==''){
                                    baseCost = this.gapFunction(baseCost, link_value, link_type)
                                }*/
                                baseCost = this.gapFunction(baseCost, roomItem.double.value, roomItem.double.link)

                            }else{
                                baseCost  = infoRooms[0].double.value;
                                if(link_type!=='' && link_value!==''){
                                    baseCost = this.gapFunction(baseCost, link_value, link_type)
                                }
                            }
                            value_return = this.gapFunction(baseCost, roomItem.sencilla.value, roomItem.sencilla.link)
                        }

                        if(link_type!=='' && link_value!==''){
                            value_return = this.gapFunction(value_return, link_value, link_type)
                        }

                        break;
                    case 'double':
                        value_return =  roomItem.double.value

                        if(roomItem.double.link!==null && roomItem.double.link!==""){
                            //console.log('baseCost', baseCost)
                            //if(baseCost>0){
                            /*if(link_type!=='' && link_value!==''){
                                baseCost = this.gapFunction(baseCost, link_value, link_type)
                            }*/
                            value_return = this.gapFunction(baseCost, roomItem.double.value, roomItem.double.link)
                            /*}else{
                                baseCost  = infoRooms[0].double.value;
                                if(link_type!=='' && link_value!==''){
                                    value_return = this.gapFunction(baseCost, link_value, link_type)
                                }
                            }*/
                        }

                        if(link_type!=='' && link_value!==''){
                            //console.log('double value_return III',value_return, link_value, link_type)
                            value_return = this.gapFunction(value_return, link_value, link_type)
                        }

                        break;
                    case 'triple':
                        value_return =  roomItem.triple.value

                        if(roomItem.triple.link!==null && roomItem.triple.link!==""){
                            if(baseCost>0){
                                /*if(link_type!=='' && link_value!==''){
                                    baseCost = this.gapFunction(baseCost, link_value, link_type)
                                }*/
                                baseCost = this.gapFunction(baseCost, roomItem.double.value, roomItem.double.link)
                            }else{
                                baseCost  = infoRooms[0].double.value;
                                if(link_type!=='' && link_value!==''){
                                    baseCost = this.gapFunction(baseCost, link_value, link_type)
                                }
                            }
                            value_return = this.gapFunction(baseCost, roomItem.triple.value, roomItem.triple.link)
                        }

                        if(link_type!=='' && link_value!==''){
                            value_return = this.gapFunction(value_return, link_value, link_type)
                        }

                        break;
                    case 'cuadruple':
                        value_return =  roomItem.cuadruple.value

                        if(roomItem.cuadruple.link!==null && roomItem.cuadruple.link!==""){
                            if(baseCost>0){
                                /*if(link_type!=='' && link_value!==''){
                                    baseCost = this.gapFunction(baseCost, link_value, link_type)
                                }*/
                                baseCost = this.gapFunction(baseCost, roomItem.double.value, roomItem.double.link)
                            }else{
                                baseCost  = infoRooms[0].double.value;
                                if(link_type!=='' && link_value!==''){
                                    baseCost = this.gapFunction(baseCost, link_value, link_type)
                                }
                            }
                            value_return = this.gapFunction(baseCost, roomItem.cuadruple.value, roomItem.cuadruple.link)
                        }

                        if(link_type!=='' && link_value!==''){
                            value_return = this.gapFunction(value_return, link_value, link_type)
                        }
                        break;
                    case 'children':
                        //console.log('children switch');
                        value_return =  roomItem.children_price

                        if(roomItem.children_link_type!==null && roomItem.children_link_type!==""){
                            if(baseCost>0){
                                baseCost = this.gapFunction(baseCost, roomItem.double.value, roomItem.double.link)
                            }else{
                                baseCost  = infoRooms[0].double.value;
                                if(link_type!=='' && link_value!==''){
                                    baseCost = this.gapFunction(baseCost, link_value, link_type)
                                }
                            }
                            value_return = this.gapFunction(baseCost, roomItem.children_price, roomItem.children_link_type)
                        }

                        if(link_type!=='' && link_value!==''){
                            value_return = this.gapFunction(value_return, link_value, link_type)
                        }

                        break;
                    case 'jr':
                        //console.log('jr switch');
                        value_return =  roomItem.jrs_price

                        if(roomItem.jr_link_type!==null && roomItem.jr_link_type!==""){
                            if(baseCost>0){
                                baseCost = this.gapFunction(baseCost, roomItem.double.value, roomItem.double.link)
                            }else{
                                baseCost  = infoRooms[0].double.value;
                                if(link_type!=='' && link_value!==''){
                                    baseCost = this.gapFunction(baseCost, link_value, link_type)
                                }
                            }
                            value_return = this.gapFunction(baseCost, roomItem.jrs_price, roomItem.jr_link_type)
                        }

                        if(link_type!=='' && link_value!==''){
                            value_return = this.gapFunction(value_return, link_value, link_type)
                        }
                        break;


                    default:
                       //console.log('link no atrapado', link)
                        break;
                }


                //console.log('value_return:', value_return)


                return this.formatMoney(value_return);
            },
            calculateResults(idx_, type){

                let room_links = this.rateplan.infoRooms;

                let roomLinkBase = null

                if(room_links.length){
                    roomLinkBase = room_links[0];
                }

                let link = room_links[idx_]

                /*console.log('romomLinkBase', roomLinkBase)
                console.log('link', idx_, type)*/

                let value_aux = 0;
                let pre_value = 0;
                if(idx_===0){
                    link.base_link = '=';
                    link.double.link = '=';
                }

                switch (type) {

                    case 'base_rate':
                        value_aux = this.gapFunction(roomLinkBase.base_rate, link.base_rate, link.base_link)
                        break;
                    case 'extra_pax':
                        pre_value =  this.gapFunction(roomLinkBase.base_rate, link.base_rate, link.base_link)
                        value_aux =this.gapOnly(pre_value, link.extra_pax, link.extra_pax_link)
                        break;

                    case 'sencilla':
                        pre_value =  this.gapFunction(roomLinkBase.double.value, link.double.value, link.double.link)
                        value_aux =  this.gapFunction(pre_value, link.sencilla.value, link.sencilla.link)
                        break;
                    case 'double':
                        //pre_value =  this.gapFunction(roomLinkBase.double.value, link.double.value, link.double.link)
                        value_aux =  this.gapFunction(roomLinkBase.double.value, link.double.value, link.double.link)
                        break;
                    case 'triple':
                        pre_value =  this.gapFunction(roomLinkBase.double.value, link.double.value, link.double.link)
                        value_aux =  this.gapFunction(pre_value, link.triple.value, link.triple.link)
                        break;
                    case 'cuadruple':
                        pre_value =  this.gapFunction(roomLinkBase.double.value, link.double.value, link.double.link)
                        value_aux =  this.gapFunction(pre_value, link.cuadruple.value, link.cuadruple.link)
                        break;
                    case 'jr':
                        pre_value =  this.gapFunction(roomLinkBase.double.value, link.double.value, link.double.link)
                        value_aux =this.gapOnly(pre_value, link.jrs_price, link.jr_link_type)
                        break;
                    case 'children':
                        pre_value =  this.gapFunction(roomLinkBase.double.value, link.double.value, link.double.link)
                        value_aux =this.gapOnly(pre_value, link.children_price, link.children_link_type)
                        break;

                    default:
                       //console.log('link no atrapado', link)
                        break;
                }


                /*let base_link = room_links[0];


                if(idx_ === 0){
                    let link = room_links[idx_]
                    switch (type) {
                        case 'base_rate':
                            value_aux = this.gapFunction(base_link.base_rate.value, link.base_rate.value, "=")
                            break;

                        case 'extra_pax':
                            value_aux =this.gapOnly(base_link.base_rate.value, link.extra_pax.value, link.extra_pax.link)
                            break;
                    }
                }else{
                    let link = room_links[idx_];

                    switch (type) {

                        case 'base_rate':
                            value_aux = this.gapFunction(base_link.base_rate.value, link.base_rate.value, link.base_rate.link)
                            break;
                        case 'extra_pax':
                            let pre_value =  this.gapFunction(base_link.base_rate.value, link.base_rate.value, link.base_rate.link)
                            value_aux =this.gapOnly(pre_value, link.extra_pax.value, link.extra_pax.link)
                            break;
                    }
                }*/

                return this.formatMoney(value_aux);
            },
            gapFunction(base, value, link_type){
                let result = 0;
                base = Number(base);
                value = Number(value);
                //console.log('gapFunction', base, value, link_type)

                switch (link_type) {
                    case '+$':
                        result = (base + value);
                        break;

                    case '-$':
                        result = (base - value);
                        break;

                    case '-%':
                        result = (base - (base/100*value));

                        break;
                    case '+%':
                        result = (base + (base/100*value));
                        break;
                    case '=':
                        result = value;
                        break;
                    default:
                        result = 0;
                        break;
                }
                return result

            },
            printLog(value){
              //console.log('log', value);
            },
            gapOnly(base, value, link_type){
                let result = 0;
                base = Number(base);
                value = Number(value);

                switch (link_type) {
                    case '+$':
                        result = (base + value);
                        break;

                    case '-$':
                        result =  (base - value);
                        break;

                    case '-%':
                        result =  (base - (base/100*value));

                        break;
                    case '+%':
                        result =  (base + (base/100*value));
                        break;
                    case '=':
                        result = value;
                        break;
                    default:
                        result = 0;
                        break;
                }
                return result

            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }
        }
    }

</script>
<style lang="scss">
    .form-check {
        &.is_public{
            margin-top: 35px;
            .form-check-label{
                text-transform: uppercase;
            }
        }
    }
    .card label.subtitle{
        text-transform: none;
        color: #000;
    }


    .container-images {
        padding-top: 35px;
        .principal {
            width: 100%;
            height: 175px;
            margin-bottom: 10px;
        }
        .second{
            width: 30%;
            margin-left: 5%;
            &.first{
                margin-left: 0;
            }
        }
    }
    .title-b{
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 0;
        font-weight: 400;
    }
    .box-rooms{
        width: 100%;
        margin-bottom: 25px;
        .text-muestra{
            font-size: 14px;
            line-height: 40px;
            margin-bottom: 0;
        }
        .item-minus{
            line-height: 45px;
        }
        .form-group{
            margin-bottom: 0;
        }
        .btn-plus{
            -webkit-box-shadow: 0 10px 6px -4px #777;
            -moz-box-shadow: 0 10px 6px -4px #777;
            box-shadow: 0 10px 6px -4px #777;
            width: 35px;
            height: 35px;
            font-size: 28px;
            background-color: black;
            display: inline-block;
            border-radius: 100%;
            position: relative;
            right: 45px;
            i{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 4px;
                bottom: 0px;
                vertical-align: middle;
                display: block;
                color: #fff;

            }
        }
    }
    .box-button{
        //padding-left: 10px;
        .add-item{
            width: 100% ;
            height: 40px;
            color: #fff;
            background-color: #222222;
            border: 2px solid #000;
        }
    }
    .btn-action {
        line-height: 45px;
        font-size: 25px;
        margin-left: 15px;
    }
    .card{
        .lbl-main{
            font-weight: 600;
            margin-top: 50px;
        }

        .pt-custom{
            margin-top: 26px !important;
        }

    }
    .el-tag, .el-tag.el-tag--default {
        background-color: #eee;
    }
    .custom-error{
        label.text-danger{
            text-transform: none;
            white-space: nowrap;
        }
    }
    span.item-empty{
        font-size: 11px;
        width: 100%;
        text-align: center;
        text-transform: none;
        font-style: italic;
        color: #CACACA;
        display: inline-block;
    }
    .form-rates-ext{
        label{
            width: 70px;
        }
        .form-group{
            width: calc(100% - 100px);
            display: inline-block;
        }
        span{
            width: 30px;
        }
        span.plus-item{
            float: right;
            font-size: 25px;
        }
    }



</style>
