import BackendService from './BackendService';
import moment from 'moment';

export default class AnnulmentService extends BackendService {

    constructor() {
        super();
    }





    getAnnulments(status, property_id) {
        //console.log('get rooms');

        return this.httpCall('cancellation-policies',{
            params: {
                status: status,
                property_id: property_id
            },
        });


        //return this.httpCall('property' + property_id + '/users');
    }
    getAnnulmentsList(params) {
        //console.log('get rooms');

        return this.httpCall('cancellation-policies',{
            params: params,
        });


        //return this.httpCall('property' + property_id + '/users');
    }
    getAnnulmentId(annulment_id) {

        return this.httpCall('cancellation-policy/'+annulment_id);

        //return this.httpCall('property' + property_id + '/users');
    }

    createAnnulment(item) {

        return this.httpCall('cancellation-policy',{
            method: 'POST',
            data: item,
        });

    }
    updateAnnulment(item) {
        //console.log('user_info', user);
       //console.log('item updsyr', item)

        return this.httpCall('cancellation-policy/'+item._id,{
            method: 'PUT',
            data: item,
        });

    }
    updateStatus(id, status) {
        //console.log('user_info', user);

        return this.httpCall('cancellation-policy/'+id,{
            method: 'PUT',
            data: {
                status: status
            },
        });

    }
    deleteAnnulment(annulment_id) {
        return this.httpCall('cancellation-policy/'+annulment_id,{
            method: 'delete'
        });
    }


    createRefundableDate(itemDate, annulment_id) {
        return this.httpCall('cancellation-policy/'+annulment_id+'/refundable-date',{
            method: 'POST',
            data: {
              from:  moment(itemDate.start).format('YYYY-MM-DD'),
              to:  moment(itemDate.end).format('YYYY-MM-DD')
            },
        });
    }
    updateRefundableDate(itemDate, annulment_id) {
        return this.httpCall('cancellation-policy/'+annulment_id+'/refundable-date/'+itemDate._id,{
            method: 'PUT',
            data: {
                from:  moment(itemDate.start).format('YYYY-MM-DD'),
                to:  moment(itemDate.end).format('YYYY-MM-DD')
            },
        });
    }
    deleteRefundableDate(annulment_id, date_id) {
        return this.httpCall('cancellation-policy/'+annulment_id+'/refundable-date/'+date_id,{
            method: 'delete'
        });
    }
    createExceptionDate(itemDate, annulment_id) {
       //console.log('itemDate', itemDate)
        return this.httpCall('cancellation-policy/'+annulment_id+'/season-exception-date',{
            method: 'POST',
            data: itemDate,
        });
    }
    updateExceptionDate(itemDate, annulment_id) {
        return this.httpCall('cancellation-policy/'+annulment_id+'/season-exception-date/'+itemDate._id,{
            method: 'PUT',
            data: itemDate,
        });
    }
    deleteExceptionDate(annulment_id, date_id) {
        return this.httpCall('cancellation-policy/'+annulment_id+'/season-exception-date/'+date_id,{
            method: 'delete'
        });
    }


}
